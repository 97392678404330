import * as Sentry from '@sentry/react';
import { browserTracingIntegration, httpClientIntegration } from '@sentry/browser';
import config from '#config';

const init = () => {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    integrations: [browserTracingIntegration(), httpClientIntegration()],
    environment: config.SENTRY_ENV,
    ignoreErrors: ['Error: Incorrect token'],
  });
};

export default { init };
